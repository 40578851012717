import { faFacebook, faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from "react-share";
import Divider from "../../components/Divider";
import Grid from "../../components/Grid";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import { HTMLContent } from "../../components/Content/Content";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Typography from "../../components/Typography";

export const BlogPostTemplate = ({
  title,
  banner,
  date,
  description,
  content,
  contentComponent,
  timeToRead,
  location,
}) => {
  const PostContent = contentComponent || Content;
  const currentDate = new Date();
  const postDate = new Date(date || null);
  const dateString = Intl.DateTimeFormat("en-us", {
    day: "numeric",
    month: "short",
    year: postDate.getFullYear() !== currentDate.getFullYear() ? "numeric" : undefined,
  }).format(postDate);

  return (
    <Layout location={location}>
      <Helmet
        title={title}
        meta={[
          {
            name: "description",
            content: `Linear blog | ${description}`,
          },
        ]}
      />
      <Grid container className="py-40">
        <div className="post-image mb-40">
          <PreviewCompatibleImage imageInfo={{ image: banner, alt: title }} title={title} />
        </div>
        <Typography variant="h1" align="center" className="mb-20">
          {title}
        </Typography>
        <Typography variant="paragraph-1" color="neutral-50" align="center">
          {dateString} · {timeToRead} min read · cat
        </Typography>
        <Divider className="mt-32 mb-16" />
        <div className="d-flex justify-content-center align-center mb-32">
          <Typography>Share on:</Typography>
          <TwitterShareButton url={location?.href || location?.pathname} className="ml-8">
            <Icon icon={faTwitter} />
          </TwitterShareButton>
          <FacebookShareButton url={location?.href || location?.pathname} className="ml-8">
            <Icon icon={faFacebook} />
          </FacebookShareButton>
          <TelegramShareButton url={location?.href || location?.pathname} className="ml-8">
            <Icon icon={faTelegram} />
          </TelegramShareButton>
        </div>
        <Grid row justify="center">
          <Grid column xs={12} md={8}>
            <PostContent content={content} className="body-large typography" />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  title: PropTypes.string,
  banner: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  location: PropTypes.object,
  timeToRead: PropTypes.number,
};

BlogPostTemplate.defaultProps = {
  contentComponent: undefined,
  banner: undefined,
  date: undefined,
  location: undefined,
  title: undefined,
  description: undefined,
  timeToRead: 1,
};

const BlogPost = ({ data, location }) => {
  const { frontmatter, html, timeToRead } = data?.markdownRemark || {};

  return (
    <BlogPostTemplate
      title={frontmatter?.title}
      description={frontmatter?.description}
      date={frontmatter?.date}
      timeToRead={timeToRead}
      content={html}
      contentComponent={HTMLContent}
      location={location}
      banner={frontmatter.banner}
    />
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      timeToRead: PropTypes.number,
      html: PropTypes.node.isRequired,
    }),
  }),
  location: PropTypes.object,
};

BlogPost.defaultProps = {
  data: {
    markdownRemark: {
      html: "",
      frontmatter: {},
      timeToRead: 0,
    },
  },
  location: undefined,
};

export default BlogPost;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        description
        date
        banner
      }
    }
  }
`;
